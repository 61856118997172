import React from "react";
import { useRouteError } from "react-router-dom";
import { Navbar } from "../components/Navbar";
import { NavSection } from "../components/nav/NavSection";
import { NavItem } from "../components/nav/NavItem";
import { Scrollmarker } from "../components/Scrollmarker";
import logo from "../res/logo.svg";
import { DefaultFooter } from "../components/Footer";

const NavbarContents: React.FC = () => {
	return (
		<NavSection fill nopad>
			<NavSection right>
				<NavItem noAnimation link="/" replaceLink>
					<img src={logo} className="logo" alt="logo" />
				</NavItem>
				<NavItem link="/" replaceLink>
					<p style={{ fontFamily: "SecularOne" }}>SpencerLS.dev</p>
				</NavItem>
			</NavSection>
			<NavSection grow>
				<NavItem link="/" replaceLink>
					<p>Return to Home</p>
				</NavItem>
			</NavSection>
		</NavSection>
	);
};

const NavbarCollapsedContents: React.FC = () => {
	return (
		<NavSection>
			<NavItem noAnimation link="/" replaceLink>
				<img src={logo} className="logo" alt="logo" />
			</NavItem>
			<NavItem link="/" replaceLink>
				<p style={{ fontFamily: "SecularOne" }}>SpencerLS.dev</p>
			</NavItem>
		</NavSection>
	);
};

const NavbarSecondCollapsedContents: React.FC = () => {
	return (
		<NavSection>
			<NavItem noAnimation link="/" replaceLink>
				<img src={logo} className="logo" alt="logo" />
			</NavItem>
		</NavSection>
	);
};

const NavbarDropdownContents: React.FC = () => {
	return (
		<NavSection fill nopad column>
			<NavItem link="/" replaceLink fillWidth>
				<p>Return to Home</p>
			</NavItem>
		</NavSection>
	);
};

export const ErrorContents = () => {
	const error = useRouteError();
	console.error(error);

	return (
		<React.Fragment>
			<Navbar
				collapseSizes={[646, 353]}
				dropSize={64}
				navbarCollapsedContents={[
					<NavbarCollapsedContents />,
					<NavbarSecondCollapsedContents />,
				]}
				navbarDropdownContents={[
					<NavbarDropdownContents />,
					<NavbarDropdownContents />,
				]}
			>
				<NavbarContents />
			</Navbar>
			<Scrollmarker id="Top" shift="-6rem" />
			<div className="container">
				<h1>Oops!</h1>
				<p>Sorry, an unexpected error has occurred.</p>
				<br />
				<p>Please make sure you have the correct URL</p>
				<br />
				<p>
					ERR:&nbsp;
					<i>
						{(error as { statusText?: string }).statusText ||
							(error as { message?: string }).message}
					</i>
				</p>
			</div>
			<DefaultFooter />
		</React.Fragment>
	);
};
