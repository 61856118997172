import useWindowDimensions from "../hooks/useWindowDimensions";
import "./SideContainer.css";

interface Props {
	popText: string;
	marginTop?: string;
	maxWidth: string;
	color?: string;
	fillSize: number;
}
export const SideContainer = (props: React.PropsWithChildren<Props>) => {
	const dims = useWindowDimensions();

	return (
		<div
			className={
				"side-container" +
				(" " + props.color || "") +
				(dims.width < props.fillSize ? " filled" : "") +
				(dims.width < 534 ? " shrunk" : "") +
				(dims.width < 353 ? " collapsed" : "")
			}
			style={{
				marginTop: props?.marginTop || "0",
				maxWidth: props?.maxWidth || "",
			}}
		>
			<div className="pop-text-container">
				<span className="pop-text">{props.popText}</span>
			</div>
			<div className="side-content-container">{props.children}</div>
		</div>
	);
};
