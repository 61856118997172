import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RootContents } from "./routes/Root";
import { ErrorContents } from "./routes/Error";
import { GradContents } from "./routes/Graduation";

const router = createBrowserRouter([
	{
		path: "/",
		element: <RootContents />,
		errorElement: <ErrorContents />,
	},
	{
		path: "/graduation",
		element: <GradContents />,
	},
]);

function App() {
	return (
		<div className="App">
			<RouterProvider router={router} />
		</div>
	);
}

export default App;
