import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Scrollmarker } from "../Scrollmarker";
import { SideContainer } from "../SideContainer";

export const WhySpencerLS = () => {
	const dims = useWindowDimensions();
	return (
		<div style={{ display: "flex", flexDirection: "column", zIndex: "1" }}>
			<Scrollmarker id="WhySLS" shift="-6rem" />
			<span
				style={{
					fontSize: "clamp(1.4rem, 7vw, 2rem)",
					textAlign: dims.width < 825 ? "center" : "end",
					marginRight: dims.width < 825 ? "0" : "10.5rem",
					fontFamily: "SecularOne",
				}}
			>
				Why SpencerLS?
			</span>
			<SideContainer
				popText="Fresh"
				maxWidth="590px"
				fillSize={825}
				marginTop="2rem"
			>
				<span>designs made to&nbsp;</span>
				<span className="tertiary-color">inspire</span>
			</SideContainer>
			<SideContainer
				popText="Robust"
				maxWidth="750px"
				fillSize={825}
				color="quaternary"
			>
				<span>designs made to&nbsp;</span>
				<span className="quaternary-color">last</span>
			</SideContainer>
			<SideContainer
				popText="Fast"
				maxWidth="480px"
				fillSize={825}
				color="secondary"
			>
				<span>designs made to&nbsp;</span>
				<span className="secondary-color">work</span>
			</SideContainer>
		</div>
	);
};
