import useWindowDimensions from "../../hooks/useWindowDimensions";
import logo from "../../res/logo.svg";
import "./TwistedLayers.css";

export const TwistedLayers = () => {
	const dims = useWindowDimensions();

	const initialShift = -150;

	let vShift = 0;
	if (dims.width > 825) {
		vShift = 60;
	}

	let cShift = vShift + initialShift;

	return (
		<div style={{ position: "absolute", left: "0", right: "0" }}>
			{/* <div
				className="layer tertiary"
				style={{
					top: "calc(40vh + 84px)",
					transform: "translate(-50%, 0) rotate(15deg)",
				}}
			/>
			<div
				className="layer quaternary"
				style={{
					top: "calc(40vh + 81.2px)",
					transform: "translate(-50%, 0) rotate(10deg)",
				}}
			/> */}
			{/* <div
				className="layer tertiary"
				style={{
					top: `calc(40vh + 47.8px + ${vShift}px)`,
					transform: "translate(-50%, 0) rotate(5deg)",
				}}
			/>
			<div
				className="layer secondary"
				style={{
					top: `calc(40vh + 44px + ${vShift}px)`,
					transform: "translate(-50%, 0) rotate(0deg)",
				}}
			/> */}
			<div
				className="layer tertiary"
				style={{
					top: `${358.5 + cShift}px`,
					transform: "translate(-50%, 0) rotate(-5deg)",
				}}
			/>
			<div
				className="layer quaternary"
				style={{
					top: `${239.4 + cShift}px`,
					transform: "translate(-50%, 0) rotate(-10deg)",
				}}
			/>
			<div
				className="layer secondary"
				style={{
					top: `${120 + cShift}px`,
					transform: "translate(-50%, 0) rotate(-15deg)",
					// transform: "rotate(45deg)",
				}}
			/>
			<div
				className="layer primary"
				style={{
					top: `${0 + cShift}px`,
					transform: "translate(-50%, 0) rotate(-20deg)",
				}}
			></div>
			<img
				className="twist-logo"
				alt=""
				src={logo}
				style={{
					top: `${-128 + cShift}px`,
				}}
			/>
		</div>
	);
};
