import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/oxygen/Oxygen-Regular.ttf";
import "./fonts/source-code-pro/SourceCodePro-VariableFont_wght.ttf";
import "./fonts/secular-one/SecularOne-Regular.ttf";
import { Amplify } from "@aws-amplify/core";
import config from "./aws-exports";

Amplify.configure(config);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
