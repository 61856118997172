import React from "react";
import "./Nav.css";

interface Props {
	fillWidth?: boolean;
	noAnimation?: boolean;
	wrap?: boolean;
	link?: string;
	replaceLink?: boolean;
	scrollTo?: string;
	click?: boolean;
	onClick?: React.MouseEventHandler<HTMLDivElement>;
}
export const NavItem = (props: React.PropsWithChildren<Props>) => {
	return (
		<div
			onClick={(e) => {
				if (props.scrollTo) {
					document.getElementById(props.scrollTo)?.scrollIntoView();
				}
				if (props.link) {
					if (props.replaceLink) {
						window.open(props.link, "_self");
						console.log("hey");
					} else {
						window.open(props.link, "_blank");
					}
				}
				if (props.onClick) {
					props.onClick(e);
				}
			}}
			className={
				"nav-item" +
				((props.onClick || props.link || props.scrollTo) && !props.noAnimation
					? " animated"
					: "") +
				(props.onClick || props.link || props.scrollTo ? " pointer" : "") +
				(props.wrap ? " wrap" : "") +
				(props.fillWidth ? " fill-width" : "")
			}
		>
			{props.children}
		</div>
	);
};
