import { useEffect, useState } from "react";

export const TimeCounter = () => {
	const siteCreationDatetime = "2023-01-30T13:18:22";

	const DAY = 1000 * 60 * 60 * 24;
	const HOUR = 1000 * 60 * 60;
	const MINUTE = 1000 * 60;
	const SECOND = 1000;

	const [timeSinceSiteCreation_ms, setTimeSinceSiteCreation_ms] = useState(
		Date.now() - new Date(siteCreationDatetime).getTime()
	);

	const updateTime = () => {
		setTimeSinceSiteCreation_ms(
			Date.now() - new Date(siteCreationDatetime).getTime()
		);
	};

	useEffect(() => {
		let timer = setInterval(() => {
			updateTime();
		}, 83);

		return function cleanup() {
			clearInterval(timer);
		};
	}, []);

	return (
		<div
			style={{
				position: "absolute",
				bottom: "0",
				left: "0",
				right: "0",
				height: "4rem",
				padding: "0 1rem",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<div
				style={{
					fontFamily: "SourceCodePro",
					fontSize: "0.9rem",
					display: "flex",
					flexWrap: "wrap",
				}}
			>
				<span>
					{`${Math.floor(timeSinceSiteCreation_ms / DAY)} days ${Math.floor(
						(timeSinceSiteCreation_ms % DAY) / HOUR
					)} hours`}
					&nbsp;
				</span>
				<span>
					{`${Math.floor(
						(timeSinceSiteCreation_ms % HOUR) / MINUTE
					)} minutes ${((timeSinceSiteCreation_ms % MINUTE) / SECOND).toFixed(
						3
					)} seconds`}
				</span>
			</div>
		</div>
	);
};
