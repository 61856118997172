import React from "react";

import { Terminal } from "../components/decoration/Terminal";
import { TimeCounter } from "../components/decoration/TimeCounter";
import { TwistedLayers } from "../components/decoration/TwistedLayers";
import { Contact } from "../components/sections/Contact";
import { LetsBuild } from "../components/sections/LetsBuild";
import { PastWork } from "../components/sections/PastWork";
import { WhySpencerLS } from "../components/sections/WhySpencerLS";
import { Navbar } from "../components/Navbar";
import {
	DefaultNavbarCollapsedContents,
	DefaultNavbarContents,
	DefaultNavbarDropdownContents,
	DefaultNavbarSecondCollapsedContents,
} from "../components/Navbar";
import { Scrollmarker } from "../components/Scrollmarker";
import { DefaultFooter } from "../components/Footer";

export const RootContents = () => {
	return (
		<React.Fragment>
			<Navbar
				navbarCollapsedContents={[
					<DefaultNavbarCollapsedContents />,
					<DefaultNavbarSecondCollapsedContents />,
				]}
				navbarDropdownContents={[
					<DefaultNavbarDropdownContents />,
					<DefaultNavbarDropdownContents />,
				]}
			>
				<DefaultNavbarContents />
			</Navbar>
			<Scrollmarker id="Top" shift="-6rem" />
			<div className="container">
				<TwistedLayers />
				<Terminal />
				<div style={{ height: "28rem" }} />
				<WhySpencerLS />
				<LetsBuild />
				<Contact />
				<PastWork />
				<TimeCounter />
			</div>
			<DefaultFooter />
		</React.Fragment>
	);
};
