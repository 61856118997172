import useWindowDimensions from "../../hooks/useWindowDimensions";
import "./Terminal.css";

export const Terminal = () => {
	const dims = useWindowDimensions();

	if (dims.width < 660) {
		return (
			<div
				className={
					"terminal" +
					(dims.width > 1490 ? " tall" : dims.width < 826 ? " short" : "")
				}
				style={{
					fontSize: dims.width < 310 ? "0.9rem" : "1rem",
				}}
			>
				<div className="line">
					<span className="tertiary">spencer@designs</span>
					<span className="secondary">:~$ ls -a</span>
				</div>
				<div className={dims.width < 385 ? "listbox collapsed" : "listbox"}>
					<span className="secondary">.</span>
					<span className="secondary">..</span>
					<span className="secondary">.mobile-aesthetic</span>
					<span className="tertiary">portfolio</span>
					<span className="tertiary">projects</span>
				</div>
				<br />
				<div className="line">
					<span className="tertiary">spencer@designs</span>
					<span className="secondary">:~$</span>
				</div>
			</div>
		);
	} else {
		return (
			//<div className={dims.width >= 900 ? "terminal max-width" : "terminal"}>
			<div
				className={
					"terminal" +
					(dims.width > 1490 ? " tall" : dims.width < 826 ? " short" : "")
				}
			>
				<div className="line">
					<span className="tertiary">spencer@designs</span>
					<span className="secondary">:~$ ls -l</span>
				</div>
				<span className="secondary">total 49</span>
				<div className="line">
					<span className="secondary">
						-drwxr-xr-x 16 spencer spencer 32768 Feb 1 12:49{" "}
					</span>
					<span className="tertiary">portfolio</span>
				</div>
				<div className="line">
					<span className="secondary">
						-drwxr-xr-x 10 spencer spencer 16384 Feb 1&nbsp;&nbsp;1:02{" "}
					</span>
					<span className="tertiary">projects</span>
				</div>
				<div className="line">
					<span className="secondary">
						-drwxr-xr-x 1 spencer spencer 1024 Feb 6&nbsp;&nbsp;22:52{" "}
					</span>
					<span className="quaternary">welcome.sh</span>
				</div>
				<br />
				<div className="line">
					<span className="tertiary">spencer@designs</span>
					<span className="secondary">:~$ ./welcome.sh</span>
				</div>
				<div className="line">
					<span className="secondary">Welcome to SpencerLS!</span>
				</div>
				<br />
				<div className="line">
					<span className="tertiary">spencer@designs</span>
					<span className="secondary">:~$</span>
				</div>
			</div>
		);
	}
};
