import React from "react";
import "./Nav.css";

interface Props {
	right?: boolean;
	left?: boolean;
	bottom?: boolean;
	bottomShadow?: boolean;
	top?: boolean;
	grow?: boolean;
	fill?: boolean;
	justification?: string;
	column?: boolean;
	nopad?: boolean;
}
export const NavSection = (props: React.PropsWithChildren<Props>) => {
	return (
		<div
			className={
				"nav-section" +
				(props?.right ? " right" : "") +
				(props?.left ? " left" : "") +
				(props?.bottom ? " bottom" : "") +
				(props?.bottomShadow ? " bottom-shadow" : "") +
				(props?.top ? " top" : "")
			}
			style={{
				flexGrow: props?.grow ? 1 : 0,
				width: props?.fill ? "100%" : "auto",
				justifyContent: props?.justification ? props.justification : "center",
				flexDirection: props?.column ? "column" : "row",
				padding: props?.nopad ? "0" : "",
				// padding: props?.fill ? "0 1rem" : "",
			}}
		>
			{props.children}
		</div>
	);
};
