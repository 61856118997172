import { Scrollmarker } from "../Scrollmarker";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import "../../App.css";

import StriderImg from "../../res/StriderGarmin.png";
import NFTagImg from "../../res/NFTag.png";
import NEUtahImg from "../../res/NEUtah.png";

export const PastWork = () => {
	const dims = useWindowDimensions();

	let ifWidth = 405;
	if (dims.width < 400) {
		ifWidth = dims.width - 50;
	} else if (dims.width < 500) {
		ifWidth = 324;
	}

	return (
		<div
			className="slanted-section left"
			style={{
				backgroundColor: "var(--primary-dark)",
			}}
		>
			<Scrollmarker id="PastWork" shift="-6rem" />
			<div
				style={{
					marginRight: dims.width < 825 ? "0" : "5.5rem",
					display: "flex",
					justifyContent: dims.width < 825 ? "center" : "flex-end",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: dims.width < 825 ? "center" : "flex-end",
						maxWidth: "550px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div
							style={{
								padding: "0 1rem",
								paddingBottom: "0.5rem",

								fontSize:
									dims.width > 353
										? "clamp(0.8rem, 5.8vw, 2rem)"
										: "clamp(1rem, 7vw, 1.4rem)",
								textAlign: "center",
								fontFamily: "SecularOne",
								borderBottom: "3px solid var(--primary-light)",
							}}
						>
							<p
								style={{
									margin: 0,
									display: dims.width < 353 ? "inline-block" : "inline",
								}}
							>
								Past development
							</p>
							{dims.width > 353 ? " " : ""}
							<p
								style={{
									margin: 0,
									display: dims.width < 353 ? "inline-block" : "inline",
								}}
								className="quaternary-color"
							>
								experiences
							</p>
						</div>

						<div
							style={{
								margin: "1rem 2rem",
								fontSize: "1rem",
								display: "flex",
								flexDirection: "column",
								textAlign: "start",
							}}
						>
							<p style={{ margin: 0 }}>
								Some of my first projects are what got me to where I am today.
							</p>
							<p style={{ margin: 0 }}>I've learned a lot about</p>
						</div>
						<div
							style={{
								margin: "0rem 1rem",
								fontSize: "1.4rem",
								display: "flex",
								fontFamily: "SecularOne",
								justifyContent: "center",
							}}
						>
							<span className="tertiary-color">what&nbsp;</span>
							<span>to do</span>
						</div>
						<div
							style={{
								margin: "0rem 1rem",
								fontSize: "1.2rem",
								display: "flex",
								fontFamily: "SecularOne",
								justifyContent: "center",
							}}
						>
							<p style={{ margin: 0 }}>and</p>
						</div>
						<div
							style={{
								margin: "0rem 1rem",
								fontSize: "1.4rem",
								display: "flex",
								fontFamily: "SecularOne",
								justifyContent: "center",
							}}
						>
							<span className="tertiary-color">what not&nbsp;</span>
							<span>to do.</span>
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					height: "3px",
					width: "100%",
					backgroundColor: "var(--tertiary)",
					zIndex: "-1",
					margin: "2rem 0",
				}}
			/>
			<div
				style={{
					display: "flex",
					justifyContent: "space-evenly",
					flexWrap: "wrap",
				}}
			>
				<div
					style={{
						width: `${ifWidth}px`,
						padding: "0 1rem 1rem 1rem",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							fontFamily: "SourceCodePro",
							fontSize: "1.2rem",
							paddingBottom: "0.5rem",
						}}
					>
						<p
							style={{
								cursor: "pointer",
							}}
							onClick={(e) => window.open("https://nftag.app", "_blank")}
						>
							NFTag
						</p>
					</div>
					<img
						style={{
							width: `${ifWidth}px`,
						}}
						alt="NFtag"
						src={NFTagImg}
					/>
					<div
						style={{
							margin: "1rem 0",
							fontSize: "1rem",
							display: "flex",
							flexDirection: "column",
							textAlign: "start",
						}}
					>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							My friend and I (see ConcreteGames LLC below) entered into
							Southern Utah's&nbsp;
							<a
								style={{ color: "white" }}
								href="https://southernutahcodecamp.org"
							>
								CodeCamp
							</a>
							&nbsp;competition in 2022.
						</p>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							The overarching rules are simple: Create the best project you can
							in 24 hours. Everything you use must be created within that time
							(besides open-source packages)
						</p>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							We built an assassin-style real-world game where you take out your
							friends by snapping a picture of them with your phone. It had a
							cool overlay with device id and other information; the idea was
							that it would be turned into an NFT and players could trade them.
						</p>
						<p style={{ margin: 0 }}>
							We didn't quite get the NFT integration working during that time,
							but the idea and basic framework was present enough to take second
							place against the other industry programmers in Southern Utah.
						</p>
					</div>
				</div>
				<div
					style={{
						width: `${ifWidth}px`,
						padding: "0 1rem 1rem 1rem",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							fontFamily: "SourceCodePro",
							fontSize: "1.2rem",
							paddingBottom: "0.5rem",
						}}
					>
						<p
							style={{
								cursor: "pointer",
							}}
							onClick={(e) => window.open("https://newerautah.com", "_blank")}
						>
							NewEraUtah.com
						</p>
					</div>
					{/* <iframe
						title="New Era Management"
						src="https://newerautah.com/login"
						width={`${ifWidth}px`}
						height={`${(if
                            Width * 15) / 9}px`}
					/> */}
					<img
						style={{
							width: `${ifWidth}px`,
						}}
						alt="New Era Utah"
						src={NEUtahImg}
					/>
					<div
						style={{
							margin: "1rem 0",
							fontSize: "1rem",
							display: "flex",
							flexDirection: "column",
							textAlign: "start",
						}}
					>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							This was the second full-stack project I ever made. My dad wanted
							a website that employees could log in to and access certain
							utility links with.
						</p>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							The styling definitely isn't the best and it has broken over time
							(I've come a long way), but besides that the implementation and
							communication with the back end is still solid.
						</p>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							Additionally there are resources for the public such as a contact
							form that sends emails with a custom emailer in node.js and e-mail
							templates that I designed myself.
						</p>
						<p style={{ margin: 0 }}>
							* FYI if you go to the link, just know that a bunch of styles are
							broken on smaller devices *
						</p>
					</div>
				</div>
				<div
					style={{
						width: `${ifWidth}px`,
						padding: "0 1rem 1rem 1rem",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							fontFamily: "SourceCodePro",
							fontSize: "1.2rem",
							paddingBottom: "0.5rem",
						}}
					>
						<p
							style={{
								cursor: "pointer",
							}}
							onClick={(e) =>
								window.open(
									"https://apps.garmin.com/en-US/apps/f155897d-002a-4465-8271-6271b0d8909c",
									"_blank"
								)
							}
						>
							Strider
						</p>
					</div>
					<img
						style={{
							width: `${ifWidth}px`,
						}}
						alt="Strider App"
						src={StriderImg}
					/>
					{/* <iframe
						title="Strider"
						src="https://apps.garmin.com/en-US/apps/f155897d-002a-4465-8271-6271b0d8909c"
						width={`${ifWidth}px`}
						height={`${(ifWidth * 15) / 9}px`}
					/> */}
					<div
						style={{
							margin: "1rem 0",
							fontSize: "1rem",
							display: "flex",
							flexDirection: "column",
							textAlign: "start",
						}}
					>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							As per the description in the image, I made this watch app because
							I was into running. I had to learn the Monkey C programming
							language, which has some quirks to it.
						</p>
					</div>
				</div>
				<div
					style={{
						width: `${ifWidth}px`,
						padding: "0 1rem 1rem 1rem",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							fontFamily: "SourceCodePro",
							fontSize: "1.2rem",
							paddingBottom: "0.5rem",
						}}
					>
						<p
							style={{
								cursor: "pointer",
							}}
						>
							My first full-stack&nbsp;site?
						</p>
					</div>
					<div
						style={{
							margin: "1rem 0",
							fontSize: "1rem",
							display: "flex",
							flexDirection: "column",
							textAlign: "start",
						}}
					>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							Though I no longer have access to it, the first full-stack website
							I ever created was written in PHP
						</p>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							I think I was twelve or thirteen years old at the time, and I had
							a bright interest in computer programming. I knew that if I put in
							the effort I could create whatever I wanted.
						</p>
						<p style={{ margin: 0 }}>
							The website featured a fully functional account system, and
							surprisingly had decent design, though very simple.
						</p>
					</div>
				</div>
				<div
					style={{
						width: `${ifWidth}px`,
						padding: "0 1rem 1rem 1rem",
					}}
				>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							fontFamily: "SourceCodePro",
							fontSize: "1.2rem",
							paddingBottom: "0.5rem",
						}}
					>
						<p
							style={{
								cursor: "pointer",
							}}
							onClick={(e) => window.open("https://newerautah.com", "_blank")}
						>
							ConcreteGames LLC
						</p>
					</div>
					<div
						style={{
							margin: "1rem 0",
							fontSize: "1rem",
							display: "flex",
							flexDirection: "column",
							textAlign: "start",
						}}
					>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							A friend and I (&nbsp;
							<a
								style={{ color: "white" }}
								href="https://walterjs.dev"
								rel="noreferrer"
								target="_blank"
							>
								see his site
							</a>
							&nbsp;) formed a little partnership when we were kids. We first
							wanted to learn how to make games, but that wasn't really our
							forte.
						</p>
						<p style={{ margin: 0, marginBottom: "0.5rem" }}>
							We went on to build a decent-looking website, create some game
							prototypes, work on arduino projects, and compete in Southern
							Utah's&nbsp;
							<a
								style={{ color: "white" }}
								href="https://southernutahcodecamp.org"
							>
								CodeCamp
							</a>
							&nbsp;competition.
						</p>
						<p style={{ margin: 0 }}>
							In 2022 we took second place in the industry division against some
							tough competition. We built a prototype of a real-world
							NFT-integrated game we called NFTag.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
