import React from "react";
import gradPhoto from "../res/graduation_photo.png";
import gradAnnouncement from "../res/graduation_announcement.png";
import "./Graduation.css";
import { Navbar } from "../components/Navbar";
import { NavSection } from "../components/nav/NavSection";
import { NavItem } from "../components/nav/NavItem";
import logo from "../res/logo.svg";
import { Scrollmarker } from "../components/Scrollmarker";
import { DefaultFooter } from "../components/Footer";

const NavbarContents: React.FC = () => {
	return (
		<NavSection fill nopad>
			<NavSection right>
				<NavItem noAnimation link="/" replaceLink>
					<img src={logo} className="logo" alt="logo" />
				</NavItem>
				<NavItem link="/" replaceLink>
					<p style={{ fontFamily: "SecularOne" }}>SpencerLS.dev</p>
				</NavItem>
			</NavSection>
			<NavSection grow>
				<NavItem link="/" replaceLink>
					<p>Return to main app</p>
				</NavItem>
			</NavSection>
		</NavSection>
	);
};

const NavbarCollapsedContents: React.FC = () => {
	return (
		<NavSection>
			<NavItem noAnimation link="/" replaceLink>
				<img src={logo} className="logo" alt="logo" />
			</NavItem>
			<NavItem link="/" replaceLink>
				<p style={{ fontFamily: "SecularOne" }}>SpencerLS.dev</p>
			</NavItem>
		</NavSection>
	);
};

const NavbarSecondCollapsedContents: React.FC = () => {
	return (
		<NavSection>
			<NavItem noAnimation link="/" replaceLink>
				<img src={logo} className="logo" alt="logo" />
			</NavItem>
		</NavSection>
	);
};

const NavbarDropdownContents: React.FC = () => {
	return (
		<NavSection fill nopad column>
			<NavItem link="/" replaceLink fillWidth>
				<p>Return to main app</p>
			</NavItem>
		</NavSection>
	);
};

export const GradContents = () => {
	return (
		<React.Fragment>
			<Navbar
				noGlow
				collapseSizes={[646, 353]}
				dropSize={64}
				navbarCollapsedContents={[
					<NavbarCollapsedContents />,
					<NavbarSecondCollapsedContents />,
				]}
				navbarDropdownContents={[
					<NavbarDropdownContents />,
					<NavbarDropdownContents />,
				]}
			>
				<NavbarContents />
			</Navbar>
			<Scrollmarker id="Top" shift="-6rem" />
			<div className="container">
				<div className="grad-announcement-alignment-container">
					<div className="grad-announcement-container">
						<img
							className="grad-announcement-image"
							src={gradAnnouncement}
							alt="The graduation announcement. It didn't load for some reason :/"
						/>
						{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
						<img
							className="grad-announcement-image"
							src={gradPhoto}
							alt="A sexy photo of Spencer Smith. It didn't load for some reason :/"
						/>
					</div>
				</div>
			</div>
			<DefaultFooter noGlow />
		</React.Fragment>
	);
};
