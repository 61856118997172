import { Scrollmarker } from "../Scrollmarker";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export const LetsBuild = () => {
	const dims = useWindowDimensions();
	return (
		<div
			className="slanted-section right"
			style={{
				backgroundColor: "var(--primary-dark)",
			}}
		>
			<Scrollmarker id="LetsBuild" shift="-2rem" />
			<div
				style={{
					marginLeft: dims.width < 825 ? "0" : "5.5rem",
					display: "flex",
					justifyContent: dims.width < 825 ? "center" : "flex-start",
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: dims.width < 825 ? "center" : "flex-start",
						maxWidth: "550px",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div
							style={{
								padding: "0 1rem",
								paddingBottom: "0.5rem",

								fontSize:
									dims.width > 353
										? "clamp(0.8rem, 5.8vw, 2rem)"
										: "clamp(1rem, 7vw, 1.4rem)",
								textAlign: "center",
								fontFamily: "SecularOne",
								borderBottom: "3px solid var(--primary-light)",
							}}
						>
							<p
								style={{
									margin: 0,
									display: dims.width < 353 ? "inline-block" : "inline",
								}}
							>
								Let's build something
							</p>
							{dims.width > 353 ? " " : ""}
							<p
								style={{
									margin: 0,
									display: dims.width < 353 ? "inline-block" : "inline",
								}}
								className="tertiary-color"
							>
								special
							</p>
						</div>

						<div
							style={{
								margin: "1rem 2rem",
								fontSize: "1rem",
								display: "flex",
								textAlign: "start",
							}}
						>
							<p style={{ margin: 0 }}>
								Your opinions, feedback, and ideas are critical to the success
								of your design. I want to work with you because
							</p>
						</div>
						<div
							style={{
								margin: "0rem 1rem",
								fontSize: "1.4rem",
								display: "flex",
								fontFamily: "SecularOne",
								justifyContent: "center",
							}}
						>
							<span>I take you&nbsp;</span>
							<span className="quaternary-color">seriously.</span>
						</div>
						<div
							style={{
								margin: "1rem 2rem",
								fontSize: "1rem",
								display: "flex",
								textAlign: "start",
							}}
						>
							<p style={{ margin: 0 }}>
								I will build high-end websites and apps, custom software,
								serverside solutions, and more.
							</p>
						</div>
						<div
							style={{
								margin: "1rem 2rem",
								fontSize: "1rem",
								display: "flex",
								textAlign: "start",
							}}
						>
							<p style={{ margin: 0 }}>
								Your vision is what matters. Let's bring it to life today.
							</p>
						</div>
						<div
							style={{
								margin: "1rem 2rem",
								fontSize: "1rem",
								display: "flex",
								justifyContent: dims.width < 825 ? "center" : "flex-start",
								textAlign: "start",
							}}
						>
							<div
								style={{
									position: "absolute",
									height: "3px",
									width: "calc(100% + 7.5rem)",
									marginLeft: "-7.5rem",
									marginTop: "calc(1.5rem + 1.5px)",
									backgroundColor: "var(--tertiary)",
									zIndex: "-1",
								}}
							/>
							<div
								onClick={(e) => {
									document.getElementById("Contact")?.scrollIntoView();
								}}
								style={{
									backgroundColor: "var(--tertiary)",
									borderRadius: "0.5rem",
									padding: "1rem 2rem",
									cursor: "pointer",
								}}
							>
								<p style={{ margin: 0 }}>Get a Quote</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
