import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Scrollmarker } from "../Scrollmarker";
import "./Contact.css";
import { API } from "aws-amplify";
import { createMessage } from "../../../src/graphql/mutations";

import { useState } from "react";

const defaultFormState = {
	name: "",
	email: "",
	type: "Contact",
	message: "",
};

const re_email =
	/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const Contact = () => {
	const [formState, setFormState] = useState(defaultFormState);

	const updateFormState = (key: string, value: string) => {
		setFormState({ ...formState, [key]: value });
	};

	const sendContact = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!formState.name.replace(/\s/g, "")) return alert("A name is required!");
		if (!formState.email.replace(/\s/g, ""))
			return alert("An email is required!");
		if (!re_email.test(formState.email))
			return alert("Invalid e-mail address!");
		if (!formState.type.replace(/\s/g, ""))
			return alert("A subject is required!");
		if (!formState.message.replace(/\s/g, ""))
			return alert("A message is required!");

		await API.graphql({
			query: createMessage,
			variables: {
				input: {
					name: formState.name,
					email: formState.email,
					type: formState.type,
					message: formState.message,
				},
			},
		});
		alert("Message Sent!");
		setFormState(defaultFormState);
	};

	return (
		<div>
			<Scrollmarker id="Contact" shift="-6rem" />
			<div className="contact-container">
				<div className="contact-box">
					<div className="contact-details">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignContent: "center",
								width: "100%",
							}}
						>
							<div style={{ display: "flex", flexGrow: "1" }}>
								<span
									style={{
										fontSize: "2rem",
										fontFamily: "SecularOne",
										height: "3rem",
										lineHeight: "3rem",
										verticalAlign: "middle",
									}}
								>
									Contact
								</span>
							</div>
							<div className="socials-container">
								<div
									className="social-icon"
									onClick={(e) => {
										window.open(
											"https://www.linkedin.com/in/spencerliamsmith/",
											"_blank"
										);
									}}
								>
									<FontAwesomeIcon icon={faLinkedin} fontSize="2rem" />
								</div>
								<div
									className="social-icon"
									onClick={(e) => {
										window.open("https://github.com/Spencer1O1", "_blank");
									}}
								>
									<FontAwesomeIcon icon={faGithub} fontSize="2rem" />
								</div>
							</div>
						</div>
						<div className="phone-box">
							<p
								style={{
									maxWidth: "14rem",
								}}
							>
								Phone: +1 (435) 359-8413
							</p>
						</div>
						<p style={{ maxWidth: "14rem" }}>
							I'll get back to you as soon as I'm available!
						</p>
					</div>
					<form className="contact-form" onSubmit={sendContact}>
						<div className="input-container">
							<label htmlFor="name">Name *</label>
							<input
								id="name"
								name="name"
								type="text"
								placeholder="Albert Einstein"
								value={formState.name}
								onChange={(e) => updateFormState("name", e.target.value)}
							/>
						</div>
						<div className="input-container">
							<label htmlFor="email">E-mail *</label>
							<input
								id="email"
								name="email"
								type="text"
								placeholder="albert.einstein@domain.com"
								value={formState.email}
								onChange={(e) => updateFormState("email", e.target.value)}
							/>
						</div>
						<div className="input-container">
							<label htmlFor="type">Subject *</label>
							<select
								id="type"
								name="type"
								value={formState.type}
								onChange={(e) => updateFormState("type", e.target.value)}
							>
								<option value="Contact">Contact</option>
								<option value="Request a Quote">Request a Quote</option>
								<option value="Other">Other</option>
							</select>
						</div>
						<div className="input-container">
							<label htmlFor="message">Message *</label>
							<textarea
								id="message"
								name="message"
								placeholder="Hey Spencer! I'd like to set up a meeting about developing..."
								rows={5}
								value={formState.message}
								onChange={(e) => updateFormState("message", e.target.value)}
							/>
						</div>
						<div className="input-container input-container-submit">
							<input
								id="send-message"
								name="send-message"
								type="submit"
								value="Send Message"
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
