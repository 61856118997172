import React from "react";
import "./Footer.css";
import { NavSection } from "./nav/NavSection";
import { NavItem } from "./nav/NavItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../res/logo.svg";
import { faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";

interface Props {
	noGlow?: boolean;
}
export const Footer = (props: React.PropsWithChildren<Props>) => {
	return (
		<div className={props.noGlow ? "footer no-glow" : "footer"}>
			{props.children}
		</div>
	);
};

export const DefaultFooter = ({ noGlow }: { noGlow?: boolean }) => {
	return (
		<Footer noGlow={noGlow}>
			<NavSection fill bottom />
			<NavSection fill nopad bottom>
				<NavItem link="https://www.linkedin.com/in/spencerliamsmith/">
					<FontAwesomeIcon icon={faLinkedin} fontSize="2rem" />
				</NavItem>
				<NavItem noAnimation scrollTo="Top">
					<img src={logo} className="logo large" alt="logo" />
				</NavItem>
				<NavItem link="https://github.com/Spencer1O1">
					<FontAwesomeIcon icon={faGithub} fontSize="2rem" />
				</NavItem>
			</NavSection>
			<NavSection fill nopad>
				<NavItem>
					<span>© 2023 SpencerLS</span>
				</NavItem>
				<NavItem>
					<span>&nbsp;All rights reserved</span>
				</NavItem>
			</NavSection>
		</Footer>
	);
};
