import React from "react";

interface Props {
	dropSize: number;
	dropped?: boolean;
	onClicked?: React.MouseEventHandler<HTMLDivElement>;
}
export const NavDropdown = (props: React.PropsWithChildren<Props>) => {
	return (
		<div
			className="nav-dropdown"
			style={{
				marginTop: props.dropped ? 0 : -props.dropSize,
			}}
			onClick={props.onClicked}
		>
			{props.children}
		</div>
	);
};
