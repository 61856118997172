import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { NavSection } from "./nav/NavSection";
import "./Navbar.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { NavItem } from "./nav/NavItem";
import { NavDropdown } from "./nav/NavDropdown";

import logo from "../res/logo.svg";

export const DefaultNavbarContents: React.FC = () => {
	return (
		<NavSection fill nopad>
			<NavSection right>
				<NavItem noAnimation scrollTo="Top">
					<img src={logo} className="logo" alt="logo" />
				</NavItem>
				<NavItem scrollTo="Top">
					<p style={{ fontFamily: "SecularOne" }}>SpencerLS.dev</p>
				</NavItem>
			</NavSection>
			<NavSection grow>
				<NavItem scrollTo="WhySLS">
					<p>About</p>
				</NavItem>
				<NavItem scrollTo="Contact">
					<p>Quote</p>
				</NavItem>
				<NavItem scrollTo="Contact">
					<p>Contact</p>
				</NavItem>
				<NavItem scrollTo="PastWork">
					<p>Experience</p>
				</NavItem>
				<NavItem link="/graduation" replaceLink>
					<p>Graduation</p>
				</NavItem>
			</NavSection>
		</NavSection>
	);
};

export const DefaultNavbarCollapsedContents: React.FC = () => {
	return (
		<NavSection>
			<NavItem noAnimation scrollTo="Top">
				<img src={logo} className="logo" alt="logo" />
			</NavItem>
			<NavItem scrollTo="Top">
				<p style={{ fontFamily: "SecularOne" }}>SpencerLS.dev</p>
			</NavItem>
		</NavSection>
	);
};

export const DefaultNavbarSecondCollapsedContents: React.FC = () => {
	return (
		<NavSection>
			<NavItem noAnimation scrollTo="Top">
				<img src={logo} className="logo" alt="logo" />
			</NavItem>
		</NavSection>
	);
};

export const DefaultNavbarDropdownContents: React.FC = () => {
	return (
		<NavSection fill nopad column>
			<NavItem scrollTo="WhySLS" fillWidth>
				<p>About</p>
			</NavItem>
			<NavItem scrollTo="Contact" fillWidth>
				<p>Quote</p>
			</NavItem>
			<NavItem scrollTo="Contact" fillWidth>
				<p>Contact</p>
			</NavItem>
			<NavItem scrollTo="PastWork">
				<p>Experience</p>
			</NavItem>
			<NavItem link="/graduation" replaceLink>
				<p>Graduation</p>
			</NavItem>
		</NavSection>
	);
};

const defaultCollapseSizes = [768, 353];
const defaultDropSize = 320;

interface Props {
	navbarCollapsedContents: Array<React.ReactNode>;
	navbarDropdownContents: Array<React.ReactNode>;
	collapseSizes?: Array<number>;
	dropSize?: number;
	noGlow?: boolean;
}

export const Navbar = (props: React.PropsWithChildren<Props>) => {
	const dims = useWindowDimensions();
	const [dropped, setDropped] = React.useState(false);

	const dropDown = () => {
		setDropped(!dropped);
	};

	let collapseIndex = -1;
	while (
		dims.width <
		(props.collapseSizes
			? props.collapseSizes[collapseIndex + 1]
			: defaultCollapseSizes[collapseIndex + 1])
	) {
		collapseIndex++;
	}

	if (collapseIndex >= 0) {
		return (
			<>
				<div className={props.noGlow ? "navbar no-glow" : "navbar"}>
					<NavSection fill nopad bottomShadow={dropped}>
						{props.navbarCollapsedContents[collapseIndex]}
						<NavSection grow justification="flex-end">
							<NavItem onClick={dropDown} click>
								<FontAwesomeIcon icon={faBars} fontSize="2rem" />
							</NavItem>
						</NavSection>
					</NavSection>
					<NavDropdown
						dropSize={props.dropSize || defaultDropSize}
						dropped={dropped}
						onClicked={() => {
							setDropped(false);
						}}
					>
						{props.navbarDropdownContents[collapseIndex]}
					</NavDropdown>
				</div>
			</>
		);
	} else {
		return (
			<div className={props.noGlow ? "navbar no-glow" : "navbar"}>
				{props.children}
			</div>
		);
	}
};
